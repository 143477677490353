import React, { useState, Component } from 'react'
import { Link } from 'react-router-dom';
// import Create from './Travels/Create';

const  Content  = () => {
  const [optionMenu, setOptionMenu] = useState(0)
  const [bedRoomSelected, setBedRoomSelected] = useState(null)

  const optionHandler = (opt) => {
    if (bedRoomSelected && optionMenu) return setBedRoomSelected(null)
    console.log(opt)
    setOptionMenu(opt)
  }

  const beedRoomHandler = (bedroom) => {
    console.log(bedroom)
    setBedRoomSelected(bedroom)
  }
  
  const selectOption = (opt) => {
    console.log(opt)
  }
      return (
        <div className='main-intranet'>
          {
            (optionMenu === 0) ?
            <div className='row' style={{margin: "10%"}}>
              <button class="main-btn btn-filled col-12" onClick={() => optionHandler(1)}>ADMINISTRAR HABITACIONES</button>
              <button class="main-btn btn-filled col-12" onClick={() => optionHandler(2)}>RESERVAS </button>
              {/* <button class="main-btn btn-filled col-3" onClick={() => optionHandler(3)}>CLIENTES </button> */}
              <button class="main-btn btn-filled col-12" onClick={() => optionHandler(4)}>PROVEEDORES </button>
            </div>
            :
            <button class="main-btn btn-filled col-12" onClick={() => optionHandler(0)}>ATRAS </button>
          }
        {
          (optionMenu && optionMenu === 1) ?
          <div className='row' style={{margin: "0 5%"}}>
            <div className="about-text-box">
                <div className="row align-items-center">
                      <h2 className='col-12 text-center'><b>GESTIONA TUS HABITACIONES</b></h2>
                    <div className="col-lg-4">
                        <div className="about-img">
                            <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2a/39/15/5b/la-habitacion-matrimonial.jpg?w=1200&h=-1&s=1" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="about-text">
                          {
                            !(optionMenu && bedRoomSelected) ?
                            <div>
                              <h3>SELECCIONA</h3>
                              <p>
                                  Administra el estado y más acerca de tus habitaciones 
                              </p>
  
                              </div>
                              : null
                          }
                            {
                              (optionMenu && bedRoomSelected) ?
                              <div className='row'>
                                <h3>Habitacion seleccionada #{bedRoomSelected && bedRoomSelected}</h3>
                                <div className='col-12' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(1)}>VER DISPONIBILIDAD</Link>
                                </div>
                              <div className='col-12' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(2)}>RESERVAR</Link>
                                </div>
                               {/*<div className='col-12' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(3)}># 03</Link>
                                </div> */}
                                </div>
                              :
                              <div className='row'>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(1)}># 01</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(2)}># 02</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(3)}># 03</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(4)}># 04</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(5)}># 05</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(6)}># 06</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(7)}># 07</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(8)}># 08</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(9)}># 09</Link>
                                </div>
                                <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(10)}># 10</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(11)}># 11</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(12)}># 12</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(13)}># 13</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(14)}># 14</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(15)}># 15</Link>
                                </div>
                              <div className='col-md-2 col-xs-2 col-sm-2' style={{margin: "1%"}}>
                                  <Link className="main-btn btn-filled" onClick={() => beedRoomHandler(16)}># 16</Link>
                                </div>
                          </div>
                            
                            }
                        </div>
                    </div> 
                </div>
            </div>
          </div>
          : null
        }
          <div className='row'>
            {/* <Create /> */}
          </div>
        </div>
      )
  }

  export default Content;
  