import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Headerthree';
import Footer from '../layouts/Footertwo';
import Content from '../sections/hotel/Content';
// import HeaderIntranet from '../layouts/HeaderIntranet';

const pagelocation = 'Intranet'

class Hotel extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>SISTEMA | {pagelocation}</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                {/* <HeaderHotel /> */}
                <Content/>
                {/* <Footer footer={{ style:"light-theme", logo:"assets/img/logo.png" }}/> */}
            </Fragment>
        );
    }
}

export default Hotel;